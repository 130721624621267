.menu-search input {
    box-sizing: border-box;
    width: max-content;
    background-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 12px;
    padding-left: 40px;
    border: none;
    opacity: 1;
}

.menu-search input:focus {
    outline: none;
}

.menu-search input::placeholder {
    color: #FFFFFF;
}

.give-bonus-modal-amount-input:focus,
.give-bonus-modal-reason-input:focus {
    opacity: 1 !important;
    outline: none;
    border: 1px solid #282B54;
}

.give-bonus-modal-amount-input,
.give-bonus-modal-reason-input {
    display: block;
    width: 100%;
    border: 1px solid rgba(40, 43, 83, 0.15);
    border-radius: 8px;
    margin: 20px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 120%;
    color: #282B54;
    opacity: 1;
    background-color: transparent;
}

.give-bonus-modal-amount-input {
    padding: 12px 10px;
}

.give-bonus-modal-reason-input {
    height: 250px;
    overflow-y: auto;
    opacity: 0.15;
    padding: 10px;
    resize: none;
}

.swal2-popup ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}

.swal2-popup ::-webkit-scrollbar-thumb {
    background: rgba(27, 28, 29, 0.3); 
    border-radius: 4px;
}

.comment-ticket-modal-container input[type="file"] {
    display: none;
}

.add-hot-offer-container .select-container-static.slim.multiple {
    padding-right: 32px;
}

.add-hot-offer-container .select-container-static.slim .label-text {
    padding: 3px 0;
    text-overflow: ellipsis;
    width: 100px;
    min-width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.add-hot-offer-container .select-container .select-option-icon {
    right: -13px;
}

.select-container-static {
    position: relative;
    padding: 12px 10px;
    border: 1px solid rgba(40, 43, 83, 0.15);
    border-radius: 10px;
}

.select-container-static.slim {
    padding: 5px 32px 5px 10px;
    border-radius: 8px;
}

.select-container-static.description::before {
    content: attr(data-index);
    position: absolute;
    left: -25px;
    font-weight: 600;
}

.select-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-items {
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgba(40, 43, 83, 0.15);
    border-radius: 10px;
    color: #000000;
}

.select-items p {
    cursor: pointer;
    margin: 0;
    margin-bottom: 10px;
}

.notification-setting-label {
    display: inline-block;
}

.quiz-option-container {
    position: relative;
}

.validate-modal-import-file-name {
    display: block;
    position: absolute;
    width: max-content;
    top: 45px;
    right: 0;
}

.quiz-option-data input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.quiz-option-data .text {
    display: inline-block;
    margin-left: 33px;
}

.quiz-option-data .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    height: 20px;
    width: 20px;
    display: inline-block;
    border: 1px solid #d0d1dd;
    border-radius: 50%;
    margin-right: 10px;
}

.quiz-option-container.checked .quiz-option-data .checkmark {
    background-color: #282B54;
    outline: 1px solid #282B54;
    outline-offset: 2px;
    left: 8px;
    height: 14px;
    width: 14px;
}

.add-hot-offer-container input[type="number"],
.add-free-offer-container input[type="number"] {
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid rgba(40, 43, 83, 0.15);
}

.w-100-input {
    width: 100px;
}

.user-table-input-container{
    position: relative;
}

.user-table-input-search-icon{
    position: absolute;
    width: 18px;
    height: 18px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.user-table-input, 
.user-table-select {
    opacity: 0.5;
    color: #1B1C1E;
    border: 1.5px solid hsl(220, 5%, 11%, 0.5);
    border-radius: 4px;
    padding: 3px 11px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.user-table-input {
    padding-right: 34px;
}

.user-table-select:hover,
.user-table-select:focus
{
     padding: 3px 11px;
    border-radius: 4px;
}

.user-table-input-container {
    position: relative;
}

.user-table-input-search-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.user-table-input,
.user-table-select {
    opacity: 0.5;
    color: #1B1C1E;
    border: 1.5px solid hsl(220, 5%, 11%, 0.5);
    border-radius: 4px;
    padding: 3px 11px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.user-table-input {
    padding-right: 34px;
}

.user-table-select:hover,
.user-table-select:focus {
    padding: 3px 11px;
    border-radius: 4px;
}