.grid-container {
  background-color: #f4f4f4;
  max-width: 100vw;
  overflow-x: scroll;
}

.grid-list_flex {
  display: flex;
  flex-direction: column;
  width: max-content;
  font-family: 'Poppins';
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 20px;
  position: relative;
}

.grid-list_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  max-width: 75vw;
  margin-bottom: 15px;
}

.grid-list_search.new {
  flex-direction: column;
  align-items: initial;
  gap: 20px;
  align-self: flex-start;
}

.grid-list_search-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
}

.grid-list_flex-form {
  gap: 10px;
  display: flex;
}

.grid-list_header {
  display: flex;
  grid-gap: 20px;
  justify-items: start;
  padding: 5px;
  border-radius: 8px;
  position: sticky;
  grid-auto-flow: column;
  background-color: #ffffff;
  top: 0;
  z-index: 10;
  width: 100%;
}

.grid-list_table-row {
  display: flex;
  justify-items: start;
  padding: 16px 8px;
  grid-gap: 20px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  position: relative;
  align-items: center;
}

.grid-list_table-row.system-offer::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #4946b70d;
  background-image: url('/public/favicon-32x32.png');
  background-repeat: no-repeat;
  border-top: 3px solid #4946b736;
}

.grid-list_header.centered,
.grid-list_table-row.centered {
  justify-items: center;
}

.grid-list_table-row.disabled {
  pointer-events: none;
}

.grid-list_table-row.disabled .grid-list_basic-cell.clickable-on-disabled{
  pointer-events: auto;
  opacity: 1;
}

.grid-list_table-row.disabled .grid-list_basic-cell {
  opacity: .3;
}

.grid-list_table-row:hover {
  background-color: #f4f4f4;
  border-radius: 4px;
}

.grid-list_header-cell {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  width: 150px;
}

.grid-list_header-cell p {
  font-size: 12px;
}

.grid-list_dropdown-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.grid-list_basic-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 150px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
}
.grid-list_basic-cell.sticky {
  position: sticky;
  left: 0;
  background: #fff !important;
  opacity: 1 !important;
  color: #333;
  padding: 10px;
  height: 100px;
  z-index: 10;
  text-align: center;
  border-radius: 15px;
  display: flex;
  vertical-align: middle;
}
.grid-list_basic-cell.sticky.UniqueOfferID {
  width: 350px;
}
.grid-list_basic-cell.sticky.AppName {
  left: 280px;
}

.grid-list_basic-cell span {
  padding: 0px;
}

.grid-list_basic-cell .desc {
  text-align: left;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  color: #1b1c1e;
}

.grid-list_basic-cell_rejected,
.grid-list_basic-cell_inactive {
  color: #d31c1c !important;
}
.grid-list_basic-cell_pending {
  color: #e8ad3c !important;
}
.grid-list_basic-cell_approved,
.grid-list_basic-cell_active {
  color: #2e7d32 !important;
}

.grid-list_basic-cell_rejected.badge:before,
.grid-list_basic-cell_inactive.badge:before {
  background: #d31c1c !important;
}
.grid-list_basic-cell_pending.badge:before {
  background: #e8ad3c !important;
}
.grid-list_basic-cell_approved.badge:before,
.grid-list_basic-cell_active.badge:before {
  background: #2e7d32 !important;
}
.badge{
  display: flex;
  align-items: center;
}
.badge:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 99px;
  margin-right: 5px;
}

.grid-list_basic-cell .username {
  text-decoration-line: underline;
}

.grid-list_basic-cell_link {
  text-overflow: hidden;
  cursor: pointer;
  color: #6260e1;
}

.grid-list_basic-cell_link:visited,
.grid-list_basic-cell_link:hover,
.grid-list_basic-cell_link:focus {
  opacity: 0.5;
}

.background-grey {
  background: #f7f7f7;
}

.grid-list_countries-button {
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.grid-list_countries-button:hover,
.grid-list_countries-button:focus {
  transform: scale(1.2);
  background: #ffffff;
}

.hot-offer-modal-first .half-width,
.hot-offer-modal-fourth .half-width {
  width: calc(50% - 15px);
}

.loader{
  position: absolute;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.8);
  z-index: 999;
}

.offers-copy-icon{
  display: flex; 
  align-items: center;
  font-size: 13px; 
  margin-top: 5px;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.offers-copy-icon:hover{
  text-decoration: underline;
}

.copied{
  color: #5dba24;
}

