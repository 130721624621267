.multi-offer-container {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
}

.modal-multi-offer-step {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
}

.modal-multi-offer-step-input-wrapper {
    display: flex;
}

.modal-multi-offer-step input,
.modal-multi-offer-step svg {
    display: block;
    align-self: start;
}

.modal-multi-offer-step-number {
    margin-right: 50px;
}

.modal-multi-offer-step input,
.modal-multi-offer-step p,
.modal-multi-offer-step textarea {
    color: #1B1C1E;
    opacity: 50%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.modal-multi-offer-step-input {
    display: inline-block;
    border: none;
    width: min-content;
    resize: vertical;
    max-width: 10vw;
}

.modal-multi-offer-step-input:focus-visible {
    border-bottom: 1px solid #a7a7a7;
    outline: none;
    opacity: 100%;
}