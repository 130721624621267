.checkbox-container {
    position: relative;
    display: block;
    margin-top: 17px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-container input:checked ~ .checkmark-checkbox:after {
    display: block;
}

.checkbox-container .checkmark-checkbox:after {
    left: 9px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid #282B54;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark-checkbox {
    position: absolute;
    top: 2px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #282B54;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 0;
    background-color: transparent;
}

.checkmark-checkbox.right-checkbox {
    left: 40px;
}

.checkmark-checkbox.left-checkbox {
    z-index: 3;
}

.checkbox-container input:checked ~ .checkmark-checkbox:after {
    display: block;
}

.checkmark-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container.subscription-settings-container {
    line-height: 28px;
    padding-left: 41px;
}

.checkbox-container.subscription-settings-container-dual {
    padding-left: 73px;
}

.subscription-settings-checkbox {
    display: none;
}