.login {
  flex: 1 0 auto;
  background-color: #f4f4f4;
  width: 100%;
  height: 100vh;
}

.header {
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  padding: 28px 20px 25px 0;
}

.header * {
  text-align: right;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.login-form {
  padding: 25px 28px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.login-form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: #1b1c1e;
  margin-bottom: 6px;
}

.login-form-input {
  width: 40vw;
  opacity: 0.15;
  border: 1px solid #282b54;
  border-radius: 8px;
  height: 48px;
  padding-left: 20px;
}

.login-form-input:focus,
.login-form-input:hover {
  opacity: 1;
}

.login-form_btn {
  background: #1b1c1e;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  box-shadow: none;
  padding: 5px 30px;
  align-self: end;
  margin: auto 0;
}

.login-layout_azure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
