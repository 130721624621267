.modal-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #5dba24;
  margin-bottom: 28px;
}

.modal-popup-heading {
  color: #5dba24;
}

.modal-heading-reject {
  color: #d31c1c;
}

.modal-heading-list {
  color: #141629;
}


.modal-heading-withdrawals-due {
  color: #d31c1c;
}

.modal-heading-dark {
  color: #141629;
}

.modal-buttons-container {
  border-top: 1px solid #a7a7a7;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 25px;
}

.withdrawals-modal-line {
  color: #1b1c1e;
  margin-bottom: 15px;
  text-align: left;
}

.withdrawals-modal-line p {
  opacity: 0.8;
}

.withdrawals-modal-line span {
  font-weight: 700;
}

.withdrawals-modal-line span.indented {
  padding-left: 68px;
}

.withdrawals-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 22px;
}


.wide-modal,
.wide-modal .modal-dialog,
.wide-modal .modal-content,
.wide-modal .MuiDialog-paper {
    max-width: 1400px !important;
    width: 90vw !important;
}