.menu-bar {
    cursor: pointer;
    position: absolute;
    top: 27px;
    left: 20px;
    width: 30px;
    height: auto;
    z-index: 9;
}

.menu-search img {
    position: absolute;
    top: calc(50% - 9px);
    left: 5px;
}

.menu-bottom-container img[alt="Admin"] {
    margin-right: 10px;
}

.header img[alt="Logo"] {
    padding: 20px;
}

.select-option-icon {
    padding: inherit;
    padding-left: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
}

.select-option-icon .expanded {
    transform: rotate(-180deg);
}

.user-page-head-img{
    width: 12px;
    height: 29px;
}

.profile-card-tc {
    width: 43px;
    height: 43px;
}

.profile-card-tc.referrals {
    width: 37px;
    height: 37px;
}

.rule-icon {
    font-size: 18px;
    margin-right: 8px;
    color: #4caf50;
}