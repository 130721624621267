a {
    display: block;
    text-decoration: none;
    cursor: initial;
}

.menu-options-container a {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 10px;
    opacity: .5;
}

/* .menu-options-container a, */
.menu-options-container a * {
    cursor: pointer;
}

.menu-options-container a.active {
    opacity: 1;
    position: relative;
}

.menu-options-container a.active::before {
    content: '';
    width: 4px;
    left: -25px;
    height: 50%;
    background-color: #fff;
    position: absolute;
}